.hidden {
  display: none;
}
.select-file button {
  width: 240px;
  margin: 15px auto;
}
.story-board-btn button {
  margin-bottom: 30px;
  margin-right: 10px;
  width: 270px;
}

@media screen and (max-width: 765px) {
  .select-file button {
    width: 100%;
    margin: 15px auto;
  }
}
