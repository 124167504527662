.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.my-section {
  padding: 10px;
  width: 240px;
  margin: 10px;
}
.my-section img {
  width: 240px;
  margin: 5px auto;
}
.my-section input {
  width: 240px;
  margin: 5px auto;
}
.my-section textarea {
  width: 240px;
  height: 200px;
  margin: 5px auto;
}
.story-board {
  width: 800px;
  display: flex;
  flex-direction: row;
  margin: 30px auto;
  border: 1px solid dimgray;
  border-radius: 10px;
}
.theme {
  width: 50%;
  margin: 30px auto;
}
.theme label {
  font-weight: bold;
}
.theme-stroy-board {
  width: 80%;
  margin: 50px auto;
  border-top: 5px solid #f3969a;
  border-bottom: 5px solid #f3969a;
}
.footer {
  text-align: center;
  background-color: #f3969a;
  color: white;
  padding: 15px;
}
@media screen and (max-width: 765px) {
  .theme {
    width: 90%;
    margin: 30px auto;
  }
  .story-board {
    width: 95%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 30px auto;
    border: 1px solid dimgray;
    border-radius: 10px;
  }
  .my-section {
    padding: 10px;
    width: 95%;
    margin: 2.5%;
  }
  .my-section img {
    width: 100%;
    margin: 5px auto;
  }
  .my-section input {
    width: 100%;
    margin: 5px auto;
  }
  .my-section textarea {
    width: 100%;
    height: 200px;
    margin: 5px auto;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
